import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MaterialsServices {

  constructor(private http: HttpClient) {
  }

  public getMaterials(meta?: string) {
    const url = meta ? environment.MAIN_SYSTEM + 'api/dashboard/materials?' + meta : environment.MAIN_SYSTEM + 'api/dashboard/materials';
    return this.http.get(url);
  }

  public CreateMaterial(newMaterial) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/materials';
    return this.http.post(url, newMaterial);
  }

  public getMaterial(colorId) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/materials/' + colorId;
    return this.http.get(url);
  }

  public editMaterial(color) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/materials';
    return this.http.put(url, color);
  }

  public deleteMaterial(id: string) {
    const url = environment.MAIN_SYSTEM + `api/dashboard/materials/${id}`;
    return this.http.delete(url);
  }

}

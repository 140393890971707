import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppsServices {

  constructor(private http: HttpClient) {
  }

  public getAppsForSelection() {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/apps/get-for-selection';
    return this.http.get(url);
  }


}

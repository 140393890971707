import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiModel } from '../../shared/model/api.model';

@Injectable({
  providedIn: 'root'
})
export class StoresServices {

  constructor(private http: HttpClient) {
  }

  public getStoresForSelection(meta?: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores/get-for-selection'+ (meta ? "?" + meta : '');
    return this.http.get(url);
  }


  public getAllStores(meta?: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores' + (meta ? ("?" + meta) : '');
    return this.http.get<ApiModel<any>>(url);
  }

  public getStore(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores/' + id + '/store';
    return this.http.get<any>(url);
  }

  public getStoreCredentials(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores/' + id + '/get-credentials';
    return this.http.get<any>(url);
  }

  public deleteStore(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores/' + id;
    return this.http.delete(url);
  }

  public getStoreTypes() {
    const url = environment.MAIN_SYSTEM + 'api/seller-app/store-types/for-selection';
    return this.http.get<any>(url);
  }

  public updateStoreInfo(data: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores/update';
    return this.http.post(url, data);
  }

  public updateStoreAuth(data: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores/update-store-auth';
    return this.http.post(url, data);
  }

  public updateStoreDocuments(data: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores/update_store_documents';
    return this.http.post(url, data);
  }

  public getStorAddress(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores/' + id + '/store-address';
    return this.http.get<any>(url);
  }

  public updateStoreAddress(data: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores/update-address';
    return this.http.post(url, data);
  }

  public verifyPending(meta: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stores/approve-pending';
    return this.http.put(url, meta);
  }

}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiModel } from '../../shared/model/api.model';

@Injectable({
  providedIn: 'root'
})
export class PackingUnitsServices {

  constructor(private http: HttpClient) {
  }

  public getPackingUnits(meta?: string) {
    const url = meta ? environment.MAIN_SYSTEM + 'api/dashboard/packing-units?' + meta : environment.MAIN_SYSTEM + 'api/dashboard/packing-units';
    return this.http.get<ApiModel<any>>(url);
  }

  public getPackingUnitsList($meta = "") {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/packing-units/get-all/for-selection' + $meta;
    console.log(url);
    return this.http.get<any>(url);
  }

  public getPackingUnit(id) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/packing-units/' + id;
    return this.http.get(url);
  }

  public CreatePackingUnit(newBadge) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/packing-units';
    return this.http.post(url, newBadge);
  }

  public editPackingUnit(badge) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/packing-units/update';
    return this.http.post(url, badge);
  }

  public deletePackingUnit(id) {
    const options = {
      headers: new HttpHeaders({
      }),
      body: {
        id: id
      }
    }
    const url = environment.MAIN_SYSTEM + 'api/dashboard/packing-units';
    return this.http.delete(url, options);
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsServices {

  constructor(private http: HttpClient) {
  }

  public getProducts(meta?: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/products' + (meta ? "?" + meta : '');
    return this.http.get(url);
  }

  public getProductInfo(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/products/' + id + '/info';
    return this.http.get(url);
  }

  public getProductImageBardcode(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/products/' + id + '/barcode-images';
    return this.http.get(url);
  }

  public getProductStores(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/products/' + id + '/store-details';
    return this.http.get(url);
  }

  public deleteProduct(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/products/' + id;
    return this.http.delete(url);
  }

  public approvePendingProduct(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/products/approve-pending-product';
    const params = {
      "product_id": id
    }
    return this.http.put(url, params);
  }

  public getProductsForSelection(meta?: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/products/get-products-for-selection'+ (meta ? "?" + meta : '');
    return this.http.get(url, {});
  }

  public saveProductInfo(params: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/products/updates/info';
    return this.http.put(url, params)
  }

  public saveStoreInfo(params: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/products/updates/store-details';
    return this.http.put(url, params)
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiModel } from '../../shared/model/api.model';

@Injectable({
  providedIn: 'root'
})
export class SellersServices {

  constructor(private http: HttpClient) {
  }

  public getSellers(meta?: any) {
    const url = meta ? environment.MAIN_SYSTEM + 'api/dashboard/sellers?' + meta : environment.MAIN_SYSTEM + 'api/dashboard/sellers';
    return this.http.get<ApiModel<any>>(url);
  }

  public deleteSeller(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/sellers';
    const options = {
      headers: new HttpHeaders({
      }),
      body: {
        id: id
      }
    }
    return this.http.delete(url, options);
  }

  public getSellerDetails(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/sellers/' + id;
    return this.http.get(url);
  }

  public updateSellerData(data: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/sellers/update';
    return this.http.post(url, data);
  }

  public approvePendingSeller(meta: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/sellers/approve-pending-seller';
    return this.http.put(url, meta);
  }
  public getForSelection(meta) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/sellers/get-for-selection'+ (meta ? "?" + meta : '');;
    return this.http.get(url);
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SizesServices {

  constructor(private http: HttpClient) {
  }

  public getSizes(meta?: string) {
    const url = meta ? environment.MAIN_SYSTEM + 'api/dashboard/sizes?' + meta : environment.MAIN_SYSTEM + 'api/dashboard/sizes';
    return this.http.get(url);
  }

  public getSize(id) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/sizes/' + id;
    return this.http.get(url);
  }

  public createSiz(newSize) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/sizes';
    return this.http.post(url, newSize);
  }

  public editSize(brand) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/sizes';
    return this.http.put(url, brand);
  }

  public deleteSize(id) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/sizes/' + id;
    return this.http.delete(url);
  }

  public getSizeTypes() {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/sizes/types';
    return this.http.get(url);
  }
}

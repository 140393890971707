import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiModel } from '../../shared/model/api.model';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) { }


  public getStocksList(meta?: string) {
    const url = meta ? environment.MAIN_SYSTEM + 'api/dashboard/stock?' + meta : environment.MAIN_SYSTEM + 'api/dashboard/stock';
    return this.http.get<ApiModel<any>>(url);
  }


  getStocks(productId: any, storeId: any) {
    const url: string = environment.MAIN_SYSTEM + 'api/dashboard/products/' + productId + '/' + storeId + '/stock';
    return this.http.get(url)
  }

  public approvePendingStock(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stock/approve-pending';
    const params = {
      "id": id
    }
    return this.http.put(url, params);
  }

  public editStock(params) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stock/edit-stock';
    return this.http.post(url, params);
  }


  public rejectPendingStock(id: any) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/stock/reject-pending';
    const params = {
      "id": id
    }
    return this.http.put(url, params);
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ColorsServices {

  constructor(private http: HttpClient) {
  }

  public getColors(meta?: string) {
    const url = meta ? environment.MAIN_SYSTEM + 'api/dashboard/colors?' + meta : environment.MAIN_SYSTEM + 'api/dashboard/colors';
    return this.http.get(url);
  }

  public CreateColor(newColor) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/colors';
    return this.http.post(url, newColor);
  }

  public getColor(colorId) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/colors/' + colorId;
    return this.http.get(url);
  }

  public editColor(color) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/colors';
    return this.http.put(url, color);
  }

  public deleteColor(id: string) {
    const url = environment.MAIN_SYSTEM + `api/dashboard/colors/${id}`;
    return this.http.delete(url);
  }

  public getColorsForSelection(filter) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/colors/get-all/for-selection'+filter;
    return this.http.get(url);
  }

}

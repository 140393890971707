import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RolesServices {

  constructor(private http: HttpClient) {
  }

  public getRoles() {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/roles/get-for-selection';
    return this.http.get(url);
  }

  public getAllPermissions(){}

}

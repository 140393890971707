import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PoliciesServices {

  constructor(private http: HttpClient) {
  }

  public getPolicies(meta?: string) {
    const url = meta ? environment.MAIN_SYSTEM + 'api/dashboard/policies?' + meta : environment.MAIN_SYSTEM + 'api/dashboard/policies';
    return this.http.get(url);
  }

  public getPolicy(Id) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/policies/' + Id;
    return this.http.get(url);
  }

  public editPolicy(object) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/policies/update';
    return this.http.post(url, object);
  }


}

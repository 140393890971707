import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {deprecate} from 'util';
import {Observable} from 'rxjs';

export interface IPermission {
  name: string;
  id: number;
  is_enabled: boolean;
}

export interface IRole {
  name: string;
  id: any;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private http: HttpClient) {
  }

  public getPermissions() {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/permissions/get-permissions';
    return this.http.get(url);
  }

  public getPermissionsForAdmin() {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/permissions/get-permissions-for-admin';
    return this.http.get(url);
  }

  public togglePermission(data) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/permissions/toggle-permissions';
    return this.http.post(url, data);
  }

  public getRolesPermissions() {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/permissions/get-roles-permissions';
    return this.http.get(url);
  }
}

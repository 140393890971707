import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ShippingMethodsServices {

  constructor(private http: HttpClient) {
  }

  public getShippingMethods(meta?: string) {
    const url = meta ? environment.MAIN_SYSTEM + 'api/dashboard/shipping-methods?' + meta : environment.MAIN_SYSTEM + 'api/dashboard/shipping-methods';
    return this.http.get(url);
  }

  public getShippingMethod(Id) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/shipping-methods/' + Id;
    return this.http.get(url);
  }

  public editShippingMethod(object) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/shipping-methods/update';
    return this.http.post(url, object);
  }


}

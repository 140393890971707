import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminsServices {

  constructor(private http: HttpClient) {
  }

  public getAdmins(meta?: string) {
    const url = meta ? environment.MAIN_SYSTEM + 'api/dashboard/admins?' + meta : environment.MAIN_SYSTEM + 'api/dashboard/admins';
    return this.http.get(url);
  }

  public CreateAdmin(newAdmin) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/admins';
    return this.http.post(url, newAdmin);
  }

  public getAdmin(adminId) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/admins/' + adminId;
    return this.http.get(url);
  }

  public editAdmin(admin) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/admins/update';
    return this.http.post(url, admin);
  }

  public deleteAdmin(id: string) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/admins';
    const options = {
      headers: new HttpHeaders({
      }),
      body: {
        id: id
      }
    }
    return this.http.delete(url, options);
  }

}

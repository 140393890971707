import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiModel } from '../../shared/model/api.model';

@Injectable({
  providedIn: 'root'
})
export class BadgesServices {

  constructor(private http: HttpClient) {
  }

  public getBadges(meta?: string) {
    const url = meta ? environment.MAIN_SYSTEM + 'api/dashboard/badges?' + meta : environment.MAIN_SYSTEM + 'api/dashboard/badges';
    return this.http.get<ApiModel<any>>(url);
  }

  public getBadgesList($meta = "") {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/badges/get-all/for-selection' + $meta;
    console.log(url);
    return this.http.get<any>(url);
  }

  public getBadge(id) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/badges/' + id;
    return this.http.get(url);
  }

  public CreateBadge(newBadge) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/badges';
    return this.http.post(url, newBadge);
  }

  public editBadge(badge) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/badges/update';
    return this.http.post(url, badge);
  }

  public deleteBadge(id) {
    const options = {
      headers: new HttpHeaders({
      }),
      body: {
        id: id
      }
    }
    const url = environment.MAIN_SYSTEM + 'api/dashboard/badges';
    return this.http.delete(url, options);
  }
}
